import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationArgs } from 'src/app/shared/models/pagination.args.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public apiUrl = environment.apis.baseUrl;
  paginationArgs: PaginationArgs = { pageNumber: 1, pageSize: 10 };

  constructor(private http: HttpClient) {}

  getCRvalues(type, paginationArgs, filter = {}): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/${type}/`, filter, {
      headers,
      params,
    });
  }

  addCR(type, data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/admin/${type}/`, data, {
      headers,
    });
  }

  updateCR(type, idCR, data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(this.apiUrl + `/admin/${type}/${idCR}`, data, {
      headers,
    });
  }

  deleteCR(type, idCR): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.delete<any>(this.apiUrl + `/admin/${type}/${idCR}`, {
      headers,
    });
  }

  getAssets(filter): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(
      this.apiUrl + `/assets/operations/filter`,
      filter,
      { headers }
    );
  }

  addAssets(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/admin/assets/operations`, data, {
      headers,
    });
  }

  deleteAssets(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/admin/assets/operations`, data, {
      headers,
    });
  }

  getNews(data, paginationArgs?): Observable<any> {
    let params = new HttpParams();
    if (paginationArgs) {
      params = params.set('per_page', `${paginationArgs.pageSize}`);
      params = params.set('page', `${paginationArgs.pageNumber}`);
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/news`, data, {
      headers,
      params,
    });
  }

  addNews(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(this.apiUrl + `/admin/news`, data, { headers });
  }

  updateNews(data): Observable<any> {    
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(this.apiUrl + `/admin/news/${data.id}`, data, {
      headers,
    });
  }

  deleteNews(id): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.delete<any>(this.apiUrl + `/admin/news/${id}`, {
      headers,
    });
  }

  getACPDescription(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + `/config/description`, { headers });
  }

  updateACPDescription(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(this.apiUrl + `/admin/config`, data, { headers });
  }

  getSubscriptions(
    filter,
    order,
    paginationArgs = this.paginationArgs
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('order_by', `${order.order_by}`);
    params = params.set('order_by_sorting', `${order.order_by_sorting}`);
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);
    return this.http.post<any>(this.apiUrl + `/subscription/filter`, filter, {
      headers,
      params,
    });
  }

  getSubscriptionById(subscriptionId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.get<any>(this.apiUrl + `/subscription/${subscriptionId}`, {
      headers,
    });
  }

  updateSubscriptionStatus(subscriptionId, status, endDate): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.post<any>(
      this.apiUrl + `/subscription/${subscriptionId}/${status}`,
      { endDate },
      { headers }
    );
  }

  updateSubscription(subscriptionId, data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.put<any>(
      this.apiUrl + `/subscription/${subscriptionId}`,
      data,
      { headers }
    );
  }

  deleteSubscription(id): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    return this.http.delete<any>(this.apiUrl + `/subscription/${id}`, {
      headers,
    });
  }

  getUsers(filter, paginationArgs = this.paginationArgs): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });
    let params = new HttpParams();
    params = params.set('per_page', `${paginationArgs.pageSize}`);
    params = params.set('page', `${paginationArgs.pageNumber}`);

    return this.http.post<any>(this.apiUrl + `/entreprise/filter`, filter, {
      headers,
      params,
    });
  }

  newsConfigDetails(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.get<any>(this.apiUrl + `/config/number_news`, { headers });
  }

  updateNewsConfig(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.put<any>(this.apiUrl + `/admin/config`, data, { headers });
  }

  addToShowCompany(data, action): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.post<any>(
      this.apiUrl + `/entreprise/show_in_acp/${action}`,
      data,
      { headers }
    );
  }

  sendAccess(userId): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    });

    return this.http.get<any>(
      this.apiUrl + `/entreprise/${userId}/credentials/reminder`,
      { headers }
    );
  }
}
